<template>
	<el-dialog width="480px" :title="contract.id ? ('No.' + contract.no + ' 合同详情')  : ('No.' + contract.order_no + ' 签约合同')" :visible.sync="contract_dialog" :fullscreen="theme.width <= 480" :close-on-click-modal="false" @closed="$refs['contract_form'].resetFields(); contract = {}" append-to-body destroy-on-close>
		<div style="position: relative;" v-if="is_show">
			<el-descriptions style="margin-top: -20px;" :labelStyle="{width: '70px'}" :title="contract.out_no || ''" :column="2" :size="theme.size" border>
				<el-descriptions-item label="创建人">{{contract.employee ? contract.employee.name : '/'}}</el-descriptions-item>
				<el-descriptions-item label="付款金额"><b style="color: #ff0000;">{{contract.price}}</b></el-descriptions-item>
				<el-descriptions-item label="甲方名称">{{contract.person}}</el-descriptions-item>
				<el-descriptions-item label="付款方式">{{payments[contract.payment] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="手机号码">{{contract.mobile}}</el-descriptions-item>
				<el-descriptions-item label="服务套餐">{{services[contract.service] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="电子邮箱">{{contract.email || '/'}}</el-descriptions-item>
				<el-descriptions-item label="服务方式">{{waies[contract.way] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="地址详情" :span="2">{{contract.regions.map((r) => r.name).join('')}} {{contract.address}}</el-descriptions-item>
				<el-descriptions-item label="签约订单" :span="2" v-if="contract.order">
					<p v-for="goods in contract.order.order_goods" :key="goods.id">{{goods.title}}</p>
				</el-descriptions-item>
				<el-descriptions-item label="有效日期" :span="2">{{contract.start_at | format_at}} 至 {{contract.end_at | format_at}}</el-descriptions-item>
				<el-descriptions-item label="备注说明" :span="2">{{contract.comment}}</el-descriptions-item>
				<el-descriptions-item label="合同附件" :span="2" v-if="contract.attachments && contract.attachments.length">
					<div class="attachment" v-for="attachment in contract.attachments" :key="attachment.id" @click="openFile(attachment.url)">
						<i class="el-icon-document"></i> {{attachment.name}}
					</div>
				</el-descriptions-item>
			</el-descriptions>
			<div style="color: rgba(255, 0, 0, 0.2); position: absolute; top: 50%; left: 50%; font-size: 38px; font-weight: bold; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="statuses[contract.status]">{{statuses[contract.status]}}</div>
		</div>
		<el-form ref="contract_form" label-width="90px" style="max-height: 58vh; padding-right: 10px; overflow: auto;" class="scroll-wrapper" :model="contract" :rules="contract_rules" :size="theme.size" v-else>
			<el-form-item label="签约产品" v-if="contract.order">
				<p v-for="goods in contract.order.order_goods" :key="goods.id">{{goods.title}}</p>
			</el-form-item>
			<el-form-item label="付款金额" style="margin-bottom: 0;" required>
				<el-col :span="8">
					<el-form-item prop="payment">
						<el-select v-model="contract.payment" placeholder="付款方式" filterable clearable>
							<el-option v-for="(payment, p) in payments" :key="p" :label="payment" :value="+p"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col style="text-align: center;" :span="2">-</el-col>
				<el-col :span="11">
					<el-form-item prop="price">
						<el-input-number style="width: 100%;" v-model.number="contract.price" placeholder="请输入成交金额" :precision="2" :step="0.01" :min="0.01"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col style="text-align: center;" :span="3" v-if="!contract.id">
					<el-link type="primary" @click="contract_dialog = false; $emit('create-fee', {source_type: 'App\\Models\\Order', source_no: contract.order_no, price: contract.price})">回款</el-link>
				</el-col>
			</el-form-item>
			<el-form-item label="甲方代理" prop="person">
				<el-input v-model="contract.person" placeholder="请输入甲方代理负责人。请填与合同一致的甲方代理负责人信息" autocomplete="off" clearable></el-input>
			</el-form-item>
			<el-form-item label="手机邮箱" style="margin-bottom: 0;" required>
				<el-col :span="9">
					<el-form-item prop="mobile">
						<el-input v-model="contract.mobile" placeholder="请输入联系手机" autocomplete="off" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col style="text-align: center;" :span="2">-</el-col>
				<el-col :span="13">
					<el-form-item prop="email">
						<el-input v-model="contract.email" placeholder="请输入文件接收邮箱" autocomplete="off" clearable></el-input>
					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item label="地址区域" prop="regions">
				<el-cascader style="width: 100%;" placeholder="请选择联系地址区域" v-model="contract.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
			</el-form-item>
			<el-form-item label="地址详情" prop="address">
				<el-input v-model="contract.address" placeholder="请输入联系地址详情" autocomplete="off" clearable></el-input>
			</el-form-item>
			<el-form-item label="有效日期" prop="effective_at">
				<el-date-picker style="width: 100%;" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" v-model="contract.effective_at"></el-date-picker>
			</el-form-item>
			<el-form-item label="自编编号" prop="out_no">
				<el-input v-model="contract.out_no" placeholder="请输入自编编号。一般外部渠道签约合同编码" autocomplete="off" clearable></el-input>
			</el-form-item>
			<el-form-item label="套餐服务" style="margin-bottom: 0;">
				<el-col :span="11">
					<el-form-item prop="service">
						<el-select v-model="contract.service" placeholder="套餐" filterable clearable>
							<el-option v-for="(service, s) in services" :key="s" :label="service" :value="+s"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col style="text-align: center;" :span="2">-</el-col>
				<el-col :span="11">
					<el-form-item prop="way">
						<el-select v-model="contract.way" placeholder="服务" filterable clearable>
							<el-option v-for="(way, w) in waies" :key="w" :label="way" :value="+w"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item label="备注说明" prop="comment">
				<el-input v-model="contract.comment" type="textarea" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="合同附件" prop="attachments">
				<el-upload
					:action="$api.URI_UPLOADS"
					:data="{type: contract_attach_cfg.type}"
					:accept="contract_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
					:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
					:on-preview="(f) => { openFile(f.url); }"
					:before-upload="beforeContractAttach"
					:file-list="contract.attachments"
					:limit="1">
					<el-button type="text" icon="el-icon-upload">点击上传</el-button>
					<p slot="tip" class="el-upload__tip" style="line-height: 1.5;">只能上传 {{contract_attach_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{contract_attach_cfg.format_size_unit}}<br />带上传合同附件直接提交审核，否则自动生成PDF文件盖章后再次上传才会提交审核</p>
				</el-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('contract_form')">{{contract.attachments && contract.attachments.length ? '提交审核' : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';

	export default {
		computed: {
			...mapState(['theme', 'regions', 'models']),
			contract_attach_cfg () {
				return this.$utils.uploadConfig('contracts');
			},
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			waies () {
				const { contracts } = this.models;
				return contracts ? contracts.waies : [];
			},
			services () {
				const { contracts } = this.models;
				return contracts ? contracts.services : [];
			},
			payments () {
				const { contracts } = this.models;
				return contracts ? contracts.payments : [];
			},
			statuses () {
				const { contracts } = this.models;
				return contracts ? contracts.statuses : [];
			},
			is_show () {
				const { status } = this.contract;
				if (status == 2) return true;
				if (status == 4) return true;
				if (status == 5) return true;
				if (status == 6) return true;
				if (status == 7) return true;
				return false;
			}
		},
		filters: {
			format_price (value) {
				console.log(value)
				return (value/100).toFixed(2);
			},
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async open (contract = {}) {
				this.contract = {...this.contract, ...contract};
				this.contract_dialog = true;
			},
			beforeContractAttach (file) {
				const { size, ext, format_size_unit } = this.contract_attach_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传 '+ext.join('/').toUpperCase()+' 合同文件不能超过 '+format_size_unit);
				return is;
			},
			onSuccess (list) {
				this.contract = {
					...this.contract,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					const res = await this.$http.post(this.$api.URI_CONTRACTS, this.contract, {headers: {loading: true}});
					const { code, msg: message, result } = res.data;
					if (code === 2) {
						this.$message.success(message);
						this.contract_dialog = false;
						this.$emit('refresh');
						return this.$utils.downloadBlob(this.$utils.base64toBlob(result.base64, 'application/octet-stream'), result.no+'.pdf');
					}
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.contract_dialog = false;
							this.$emit('refresh');
						}
					});
				});
			},
			delContract ({ id, no, price }) {
				this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (price/100).toFixed(2) + '</b>', '确定要删除合同吗？', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CONTRACTS, {params: {action: 'delete', id}, headers: {loading: true}});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.$emit('refresh');
						}
					});
				});
			},
			onStatus (r) {
				const { id, no, price, status, order, attachments } = r;
				switch (status) {
					case 1:
					case 3:
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						if (!attachments || !attachments.length) return this.$message.error('请更新上传合同附件！');
						this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (price/100).toFixed(2) + '</b>', '确定要提交合同审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\Contract'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message.success({
								message,
								onClose: () => {
									this.getContracts(this.params);
								}
							});
						});
					break;

					case 4:
					case 5:
						this.$emit('create-fee', {source_type: 'App\\Models\\Order', source_no: order.no});
					break;
					
					default:
						this.open(r);
					break;
				}
			}
		},
		data() {
			const checkPrice = (rule, value, callback) => {
				const { max_price } = this.contract;
				if (value && max_price > 0 && value > max_price) return callback(new Error('成交金额必须小于等于'+max_price));
				return callback();
			}
			const checkMobile = (rule, value, callback) => {
				const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
				if (value && !reg.test(value)) return callback(new Error('请输入正确的手机号'));
				return callback();
			}
			return {
				contract: {},
				contract_dialog: false,
				contract_rules: {
					price: [
						{ required: true, message: '请输入金额', trigger: ['blur', 'change'] },
						{ type: 'number', min: 0.01, message: '最小金额为1分', trigger: ['blur', 'change'] },
						{ validator: checkPrice, trigger: ['blur', 'change'] }
					],
					regions: [{ required: true, message: '请选择联系地址区域', trigger: ['blur', 'change'] }],
					address: [{ required: true, message: '请选择联系地址详情', trigger: ['blur', 'change'] }],
					person: [{ required: true, message: '请输入负责人', trigger: ['blur', 'change'] }],
					mobile: [
						{ required: true, message: '请输入联系手机', trigger: ['blur', 'change'] },
						{ validator: checkMobile, trigger: ['blur', 'change'] }
					],
					effective_at: [
						{ required: true, message: '请选择有效日期', trigger: ['blur', 'change'] },
						{ type: 'array', message: '有效日期必须是数组', trigger: ['blur', 'change'] }
					]
				}
			}
		}
	};
</script>
